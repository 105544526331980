<script setup lang="ts">
</script>

<template>
  <div data-layout="site/default" class="bg-forgd-bgd-500 min-h-screen font-normal text-forgd-primary-900 pt-4 bg-pattern">
    <SiteTopNav />
    <div class="max-w-forgd mx-auto px-6">
      <slot />
    </div>
    <SiteFooter class="mt-16" />
  </div>
</template>

<style scoped>
  .bg-pattern {
    background-image: url('/patterns/prelogin.svg');
  }
</style>
