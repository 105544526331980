<script setup lang="ts">
// page is already authenticated due to init.pinia.server
const auth = useAuth()

const nav = [
  {
    name: 'Tools & Services',
    href: webflowLink('/tools/'),
  },
  {
    name: 'Education',
    href: webflowLink('/education/'),
  },
  {
    name: 'About',
    href: webflowLink('/about/'),
  },
  {
    name: 'Contact Us',
    href: webflowLink('/contact/'),
  },
]
</script>

<template>
  <div class="max-w-forgd mx-auto px-6">
    <div class="glass px-6 py-5 flex items-center justify-between font-semibold rounded-lg border border-forgd-bgd-100">
      <NuxtLink :to="webflowLink('/')">
        <LogoForgd />
      </NuxtLink>

      <div class="flex items-center space-x-[40px]">
        <NuxtLink
          v-for="item in nav"
          :key="item.name"
          :to="item.href"
          :external="!!item.external"
          :target="item.external ? '_blank' : '_self'"
          class="text-forgd-primary-900"
        >
          {{ item.name }}
        </NuxtLink>
      </div>

      <div v-if="!auth.loggedIn" class="flex items-center space-x-7">
        <NuxtLink to="/login" class="text-forgd-primary-900">
          Sign In
        </NuxtLink>
        <UiLinkButton to="/register" class="rounded-lg font-semibold">
          Register
        </UiLinkButton>
      </div>
      <div v-else>
        <NuxtLink to="/playbook">
          <UiUserProjectsDropDown />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .glass {
    background: rgb(237, 237, 234, 0.8);
    backdrop-filter: blur(6px);
  }
</style>
